import Api from "@/utils/api.js";
import BaseModel from "@/models/base-model.js";
import Role from "@/models/user/role.js";
import EventModel from "@/models/event/event.js";
import { ManyToMany } from "@/models/fields.js";

export default class User extends BaseModel {
    has_permission (name) {
        for (let role of this.roles) {
            if (role.has_permission(name)) {
                return true;
            }
        }

        return false;
    }

    get is_admin () {
        for (let role of this.roles) {
            if (role.name === "Admin") {
                return true;
            }
        }

        return false;
    }

    get is_member () {
        for (let role of this.roles) {
            if (role.name === "Plante" || role.name === "Bourgeon") {
                return true;
            }
        }

        return false;
    }

    get is_subscription_valid () {
        return this.subscription_year == new Date().getFullYear();
    }

    toString () {
        return this.username;
    }

    static describe () {
        return Object.assign({},
            BaseModel.describe(),
            {
                "first_name": {
                    type: String,
                    writable: true
                },
                "last_name": {
                    type: String,
                    writable: true
                },
                "phone_number": {
                    type: String,
                    writable: true
                },
                "username": {
                    type: String
                },
                "email": {
                    type: String,
                    writable: true
                },
                "roles": {
                    type: ManyToMany(Role)
                },
                "subscription_year": {
                    type: Number,
                    writable: true
                },
                "events": {
                    type: ManyToMany(EventModel)
                },
                "is_snoring": {
                    type: Boolean,
                    writable: true
                },
                "snoring_tolerant": {
                    type: Boolean,
                    writable: true
                },
                "shoe_size": {
                    type: Number,
                    writable: true
                },
                "tshirt_size": {
                    type: String,
                    writable: true
                }
            }
        );
    }

    static get api_name () {
        return "user.user";
    }
}

